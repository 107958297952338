import currentUserStore from '../../../stores/CurrentUserStore.js';

import { VIEW_TYPE, CREDIT_TYPES } from '../constants.js';
import { hasUamPermission } from '../pages/account/utils';
import { CREDIT_ACCOUNT_PAYMENT_SOURCE_VIEW } from '../../admin/uam-granular-permissions/constants';

export const CREDIT_BUNDLE_ROLES = {
  BRAND_CONTRIBUTOR: 'Credit Brand Contributor',
  CREATOR: 'Credit Bank Program Manager Creator',
  APPROVER: 'Credit Bank Program Manager Approver',
};

export const CREDIT_PRODUCT_ROLES = {
  CREATOR: 'Credit Product Creator',
  APPROVER: 'Credit Product Approver',
};

export const OFFER_ROLES = {
  CREATOR: 'Credit Offer Creator',
  APPROVER: 'Credit Offer Approver',
};

export const SUPPORT_AGENT_ROLE = 'Credit Support Agent';

class CreditCapabilities {
  getViewType = (creditType) => {
    switch (creditType) {
      case CREDIT_TYPES.CREDIT: {
        if (this.canApproveProducts()) {
          return VIEW_TYPE.APPROVER;
        } else if (this.canCreateProducts()) {
          return VIEW_TYPE.CREATOR;
        } else {
          return VIEW_TYPE.SUPPORT_AGENT;
        }
      }

      case CREDIT_TYPES.ACQUISITION: {
        if (this.canApproveOffers()) {
          return VIEW_TYPE.APPROVER;
        } else if (this.canCreateOffers()) {
          return VIEW_TYPE.CREATOR;
        } else {
          return VIEW_TYPE.SUPPORT_AGENT;
        }
      }

      case CREDIT_TYPES.BUNDLE:
      case CREDIT_TYPES.POLICY_LIBRARY: {
        if (this.canCreateBundle()) {
          return VIEW_TYPE.CREATOR;
        } else if (this.canApproveBundle()) {
          return VIEW_TYPE.APPROVER;
        } else {
          return VIEW_TYPE.BRAND_CONTRIBUTOR;
        }
      }

      default: {
        throw new TypeError('Provided credit type is not supported.');
      }
    }
  };

  get userRoles() {
    const userRoles = currentUserStore.userStore.extendedSupplements;
    return userRoles ? userRoles : [];
  }

  // Credit Products
  canCreateProducts = () => this.userRoles.includes(CREDIT_PRODUCT_ROLES.CREATOR);

  canApproveProducts = () => this.userRoles.includes(CREDIT_PRODUCT_ROLES.APPROVER);

  canSupport = () => this.userRoles.includes(SUPPORT_AGENT_ROLE);

  canEnterApprovalQueuePage = () =>
    this.canCreateProducts() ||
    this.canApproveProducts() ||
    this.canCreateOffers() ||
    this.canApproveOffers() ||
    this.canSupport();

  canEnterProductsPage = () =>
    this.canCreateProducts() ||
    this.canApproveProducts() ||
    this.canCreateOffers() ||
    this.canApproveOffers() ||
    this.canSupport();

  canArchiveProducts = () => this.canCreateProducts() || this.canApproveProducts();

  // Acquisition Offers
  canCreateOffers = () => this.userRoles.includes(OFFER_ROLES.CREATOR);

  canApproveOffers = () => this.userRoles.includes(OFFER_ROLES.APPROVER);

  canViewQueuedOffers = () => this.canCreateOffers() || this.canApproveOffers();

  canViewOffers = () => this.canCreateOffers() || this.canApproveOffers() || this.canSupport();

  canEnterOffersPage = () =>
    this.canCreateProducts() ||
    this.canApproveProducts() ||
    this.canCreateOffers() ||
    this.canApproveOffers() ||
    this.canSupport();

  // Account Holders
  canViewAccounts = () => this.canSupport();

  canViewExtendedPII = () => this.canSupport();

  canViewAccountNotes = () => {
    return this.canCreateOffers() || this.canApproveOffers() || this.canSupport();
  };

  canCreateAccountNotes = () => this.canSupport();

  canViewCardPanAndCvv = () => this.canSupport();

  canViewPaymentSource = () => {
    return hasUamPermission([CREDIT_ACCOUNT_PAYMENT_SOURCE_VIEW]);
  };

  //Users
  canViewUsers = () => this.canSupport();

  // ============== Bundles | Policy Library | Work Queue ==============
  canCreateBundle = () => this.userRoles.includes(CREDIT_BUNDLE_ROLES.CREATOR);

  canApproveBundle = () => this.userRoles.includes(CREDIT_BUNDLE_ROLES.APPROVER);

  canContributeToBundle = () => this.userRoles.includes(CREDIT_BUNDLE_ROLES.BRAND_CONTRIBUTOR);

  canEnterBundlePage = () =>
    this.canCreateBundle() || this.canApproveBundle() || this.canContributeToBundle();

  canEnterPolicyLibrary = () => this.canCreateBundle() || this.canApproveBundle();

  canEnterBundlesPage = () =>
    this.canCreateBundle() || this.canApproveBundle() || this.canContributeToBundle();

  canEnterWorkQueue = () => this.canCreateBundle() || this.canApproveBundle();

  // ============== Templates ==============
  canEnterTemplates = () =>
    this.userRoles.includes(CREDIT_BUNDLE_ROLES.CREATOR) ||
    this.userRoles.includes(CREDIT_BUNDLE_ROLES.APPROVER);

  // ============== Credit ==============
  canEnterCreditPage = () =>
    this.canCreateProducts() ||
    this.canApproveProducts() ||
    this.canCreateOffers() ||
    this.canApproveOffers() ||
    this.canSupport() ||
    this.canCreateBundle() ||
    this.canApproveBundle() ||
    this.canContributeToBundle();
}

export default CreditCapabilities;
