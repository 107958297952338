import moment from 'moment';
import currentUserStore from '../../../../stores/CurrentUserStore';

export function getFullName(user) {
  const middleName = user.middle_name && user.middle_name.trim() ? ` ${user.middle_name[0]}.` : '';
  const fullName = `${user.first_name}${middleName} ${user.last_name}`;
  return fullName;
}

function getInstrumentType(card) {
  if (!card) return undefined;

  if (card.instrument_type === 'VIRTUAL_PAN') {
    return 'Virtual';
  } else return 'Physical';
}

function getAmount(amount, currencyCode) {
  if (amount && !isNaN(amount)) {
    if (Math.sign(amount) >= 0) return `$${amount} ${currencyCode}`;

    return `$(${amount}) ${currencyCode}`;
  }

  return;
}

export const formatPhone = (phone) => {
  if (!phone) return phone;

  const onlyDigitsPhone = phone.replace(/\D+/g, '');

  if (onlyDigitsPhone && onlyDigitsPhone.length === 10) {
    const operator = onlyDigitsPhone.slice(0, 3);
    const firstPart = onlyDigitsPhone.slice(3, 6);
    const secondPart = onlyDigitsPhone.slice(6, 10);

    return `+1 (${operator}) ${firstPart} - ${secondPart}`;
  }

  return phone;
};

export function formatGender(gender) {
  if (gender === 'M') return 'Male';
  else if (gender === 'F') return 'Female';
  else return gender;
}

export function getNameOnCard(card) {
  if (!card || !card.cardholder) {
    return undefined;
  }

  return;
}

function getLocation(card_acceptor) {
  if (card_acceptor && card_acceptor.city && card_acceptor.state)
    return `${card_acceptor.city}, ${card_acceptor.state}`;

  return;
}

export function formatTransactions(transactions = []) {
  return transactions.map(({ token, amount, createdTime, card_acceptor, card, currencyCode }) => ({
    token,
    cardToken: card ? card.token : undefined,
    merchant: card_acceptor ? card_acceptor.name : undefined,
    amount: getAmount(amount, currencyCode),
    date: moment(createdTime).format('YYYY-MM-DD HH:mm'),
    location: getLocation(card_acceptor),
    status: '',
    // method: getMethod(card_acceptor)
    lastFour: card ? card.last_four : undefined,
    cardHolderName:
      card && card.cardholder && card.cardholder.first_name
        ? card.cardholder.first_name
        : undefined,
    instrumentType: getInstrumentType(card),
  }));
}

export const getAccountHolderName = (creditAccount) => {
  if (creditAccount && Object.keys(creditAccount).length > 0) {
    const {
      accountHolderDetails: { first_name, last_name },
    } = creditAccount;

    return `${first_name} ${last_name}`;
  }
};

export function hasUamPermission(permissions = []) {
  const { userStore } = currentUserStore;
  const { hasRequiredUamPermissions } = userStore;

  if (!permissions || permissions.length === 0) {
    return false;
  }

  return hasRequiredUamPermissions(permissions);
}

export function hasAnyGivenPermission(permissions = [], legacyPermission = true) {
  const { userStore } = currentUserStore;
  const { uamGranularPermissionsActive, hasRequiredUamPermissions } = userStore;

  return uamGranularPermissionsActive ? hasRequiredUamPermissions(permissions) : legacyPermission;
}
