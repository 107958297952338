import gql from 'graphql-tag';

export const GET_SELECTED_PROGRAM_CURRENCIES = gql`
  query programCurrencies($shortCode: String) {
    programCurrencies(shortCode: $shortCode) {
      DEFAULT_CURRENCY
      PROGRAM_CURRENCIES
    }
  }
`;
