import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { HSpacer, InputSelectGroup } from '@mqd/volt-base';
import { MaskedInput } from '@mqd/volt-currency';
import { ProgramReserveContext } from '../../../../../../context/ProgramReserveContext.js';
import CurrencySelect from 'components/currency-select/CurrencySelect';
import { toJS } from 'mobx';

import s from './CurrencyInputGroup.module.css';

function CurrencyInputGroup({ transaction, type }) {
  const { updateTransaction } = useContext(ProgramReserveContext);
  const isDepositAmountInput = type === 'amount';
  const isCollateralInput = type === 'collateral';
  const programCurrenciesOptions = toJS(transaction.selectedProgramCurrencyOptions);
  const primaryCodes = transaction.selectedProgramDefaultCurrency
    ? [transaction.selectedProgramDefaultCurrency]
    : ['USD', 'CAD'];

  const inputAmountDisplayValue = isDepositAmountInput
    ? transaction.amount
    : isCollateralInput
    ? transaction.collateralAmount
    : transaction.creditAmount;

  const inputContainerTestId = isDepositAmountInput
    ? 'amount-input-container'
    : isCollateralInput
    ? 'collateral-input-container'
    : 'credit-input-container';

  const maskedInputTestId = isDepositAmountInput
    ? 'amount-input'
    : isCollateralInput
    ? 'collateral-input'
    : 'credit-input';

  return (
    <InputSelectGroup selectWidth={100} className={s.amountContainer} testId={inputContainerTestId}>
      <MaskedInput
        placeholder="Enter amount"
        required
        value={inputAmountDisplayValue}
        onValueChange={({ floatValue }) => {
          const updateValue = isDepositAmountInput
            ? { amount: floatValue }
            : isCollateralInput
            ? { collateralAmount: floatValue }
            : { creditAmount: floatValue };
          updateTransaction(transaction.id, updateValue);
        }}
        error={transaction.hasAmountErrors}
        isAllowed={() => true}
        testId={maskedInputTestId}
      />
      <HSpacer factor={0.5} />
      <CurrencySelect
        placeholder="Select currency"
        value={transaction.currency}
        error={transaction.hasAmountErrors}
        onChange={({ code }) => updateTransaction(transaction.id, { currency: code })}
        withFlags
        primaryCodes={primaryCodes}
        programCurrenciesOptions={programCurrenciesOptions}
      />
    </InputSelectGroup>
  );
}

CurrencyInputGroup.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default observer(CurrencyInputGroup);
