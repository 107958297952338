import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Flex, HSpacer, Icon, Input, Select } from '@mqd/volt-base';
import { ProgramReserveContext } from '../../../../context/ProgramReserveContext.js';
import TransactionRowHeaders from './components/TransactionRowHeaders/index.js';
import ValidationStatus from './components/ValidationStatus/ValidationStatus.js';
import CurrencyInputGroup from './components/CurrencyInputGroup/index.js';

import s from './TransactionRow.module.css';

const TransactionRow = ({ leading, removable, transaction }) => {
  const { programsOptions, updateTransaction, removeTransaction, commercialCreditActive } =
    useContext(ProgramReserveContext);

  const programValue = useMemo(
    () => {
      let selectOption;
      if (transaction.program) {
        transaction.getProgramCurrencies();
        selectOption = programsOptions.find((option) => transaction.program === option.val);
      }

      return selectOption ?? transaction.program;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programsOptions, transaction.program]
  );

  return (
    <>
      {leading && <TransactionRowHeaders commercialCreditActive={commercialCreditActive} />}

      <Flex flexDirection="row" className={s.row} testId="transaction-row">
        <div className={s.programContainer}>
          <Select
            placeholder="Select program"
            showSearch
            required
            value={programValue}
            options={programsOptions}
            onChange={(program) => updateTransaction(transaction.id, { program: program.val })}
            error={transaction.hasProgramErrors}
            testId="program-select"
          />
        </div>

        <HSpacer factor={2} />

        <CurrencyInputGroup transaction={transaction} type={'amount'} />

        <HSpacer factor={2} />

        {commercialCreditActive && (
          <>
            <CurrencyInputGroup transaction={transaction} type={'collateral'} />
            <HSpacer factor={2} />
          </>
        )}
        <CurrencyInputGroup transaction={transaction} type={'credit'} />
        <HSpacer factor={2} />
        <div className={s.memoContainer}>
          <Input
            type="text"
            maxLength={99}
            value={transaction.memo}
            onChange={(event) => updateTransaction(transaction.id, { memo: event.target.value })}
            testId="memo-input"
          />
        </div>

        <HSpacer factor={2} />

        <Flex flexDirection="column" alignItems="flex-start" className={s.alertContainer}>
          <Flex flexGrow="1" alignItems="center">
            <HSpacer factor={0.5} />
            <ValidationStatus transaction={transaction} />
          </Flex>
        </Flex>

        <HSpacer factor={2} />

        {removable && (
          <Flex alignItems="center" className={s.removeContainer} testId="remove-icon">
            <Icon
              type="clear"
              mod="default"
              factor={1}
              onClick={() => removeTransaction(transaction.id)}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};

TransactionRow.propTypes = {
  leading: PropTypes.bool,
  removable: PropTypes.bool,
  id: PropTypes.string,
  program: PropTypes.string,
  amount: PropTypes.number,
  memo: PropTypes.string,
};

TransactionRow.defaultProps = {
  leading: false,
  removable: true,
};

export default observer(TransactionRow);
