import currentUserStore from 'stores/CurrentUserStore';
import { UAM_GRANULAR_PERMISSIONS } from '@mqd/mqd-constants';

// Authorization
const defaultRequirements = {
  requiredGranularPermissions: [UAM_GRANULAR_PERMISSIONS.PROGRAM_CONFIGURATION_VIEW_AND_EDIT],
};

export const requirements = {
  ...defaultRequirements,
};

export const defaultVerifier = () => {
  return currentUserStore.userStore.disputeConfigActive;
};

export const verifier = () => {
  const isMarqetan =
    currentUserStore.userStore.userOrgName && currentUserStore.userStore.userOrgName === 'Marqeta';
  return !!isMarqetan && defaultVerifier();
};
