import React from 'react';
import { HSpacer, Icon, Pill, Text } from '@mqd/volt-base';
import { useFlipFlop } from '@mq/volt-amc-container';
import fetchFds from '../../utilities/dom/fetch-fds.js';
import css from './env.module.css';
import { URLS } from '../../utilities/config.js';

function getEnv(data = {}) {
  const newEnv = {
    MQD: window.location.origin,
    Node: URLS.nodeGateway || data.NODE_GATEWAY_URL,
    GraphQL: URLS.nodeGateway,
    FDS: data.FDS,
    version: data.version,
    DNA: data.DNA,
  };
  if (!newEnv.GraphQL || newEnv.GraphQL.includes(data.GraphQL)) {
    newEnv.GraphQL = data.GraphQL;
  }
  if (!newEnv.GraphQL.indexOf('/graphql') !== -8) {
    newEnv.GraphQL += '/graphql';
  }
  return newEnv;
}

function FDSEnv() {
  const [env, setEnv] = React.useState(getEnv());
  const flipFlop = useFlipFlop();

  React.useMemo(() => {
    if (flipFlop.get('show-fds-env')) {
      fetchFds('/env').then((response) => {
        const { data } = response || {};
        if (typeof data === 'object') {
          setEnv(getEnv(data));
        }
      });
    }
  }, [flipFlop]);

  if (!Object.keys(env)?.length) return null;

  return (
    <div className={css.container}>
      <div>
        <div className={css.title}>
          <Icon type="info" noHoverEffects />
          <HSpacer factor={1} />
          <Text type="label">FDS environment details:</Text>
        </div>
        <div className={css.content}>
          {Object.keys(env).map((key) => {
            const value = env[key];
            return (
              <div key={key}>
                <span className={css.key}>
                  <Pill type="draft">
                    <Text type="footnote">{key}</Text>
                  </Pill>
                </span>
                &nbsp;
                {key === 'version' ? (
                  <span className={css.value}>
                    <Text type="footnote" inline>
                      {value}
                    </Text>
                  </span>
                ) : (
                  <a className={css.value} href={value}>
                    <Text type="footnote" inline>
                      {value}
                    </Text>
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FDSEnv;
