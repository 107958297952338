import windowEnv from './dom/window-env.js';
import { flipFlop } from '@mq/volt-amc-container';

const janusUrlPrefix = windowEnv('JANUS_API_PREFIX', '/janus');

/**
 * Environment information.
 *
 * @type {object}
 */
export const ENV = {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  isNotProd: process.env.NODE_ENV !== 'production',
  isTesting: process.env.NODE_ENV === 'test',
};

/**
 * localStorage keys.
 * @type {object}
 */
export const KEYS = {
  // User related keys.
  userOrg: 'userOrgName',
  webToken: 'webToken',
  actingAsWebToken: 'actingAs-webToken',
  accessToken: 'accessToken',
  accessTokenAttempts: 'accessTokenAttempts',

  // Program related keys.
  programsMetadata: 'userMetadata-programs',
  favoritePrograms: 'favoritePrograms',
  activeProgram: 'selectedActiveProgram',
  janusPrograms: 'programs',
  redseaPrograms: 'redseaPrograms',
  programConfigs: 'programConfigData',

  // App data related keys.
  appData: 'applicationData',
  flipFlopFlags: 'validFeatureFlags',
};

/**
 * URLs.
 * @type {object}
 */

const nodeGateway =
  (flipFlop.get('app-api-proxy-beta', false) && window.env.APP_API_URL) ||
  (window.env.DATA_DOG_ENVIRONMENT !== 'production' && window.env.APP_API_URL)
    ? window.env.APP_API_URL
    : windowEnv('NODE_GATEWAY_URL');

export const URLS = {
  graphql: window.env.APP_API_URL ? `${window.env.APP_API_URL}/graphql` : '/graphql',
  nodeGateway: nodeGateway,
  // graphql: process.env.DIVA_USE_LOCAL_GRAPHQL === 'true' ? 'http://localhost:4000/graphql' : '/graphql',
  programs: `${janusUrlPrefix}/diva/security/orgs/programs`,
  redseaAuth: `${janusUrlPrefix}/diva/security/users/redsea/authenticate`,
  logout: `${janusUrlPrefix}/diva/security/users/redsea/logout`,
  flipFlop: '/flip-flop/flagaccessinfo',
  janusRefresh: `${janusUrlPrefix}/diva/security/webtoken/refresh`,
};

/**
 * Other configurations.
 * @type {object}
 */
export const PAGE_SIZE = 200;
export const PROXIES = ['/fds', '/dna'];
