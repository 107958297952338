import CreditCapabilities from '../../../views/credit/CreditCapabilities/index.js';
import { CREDIT_ACCOUNT_HOLDER_DETAILS_VIEW } from '../../../views/admin/uam-granular-permissions/constants';
import { hasAnyGivenPermission } from '../../../views/credit/pages/account/utils';

export const requirements = {
  flipFlopRequirements: [],
};

export const verifier = () => {
  const legacyPermission = CreditCapabilities.canViewAccounts();
  return hasAnyGivenPermission([CREDIT_ACCOUNT_HOLDER_DETAILS_VIEW], legacyPermission);
};
