import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mqd/volt-base';
import { ISOCurrencyList } from './constants.js';
import { convertToCode } from './helpers.js';
import { useOptions } from './hooks.js';

// This component will be moved to @mqd/volt-base for use in the future
// Jira: https://marqeta.atlassian.net/browse/PS-25294
/**
  Props:
    value:
      - Expects a currency code string. Ex. value={'USD' || '840'}
      - Acceptable code values in ./constants
    onChange:
      - will receive the full currency object with code and name. Ex. { code: 'USD', number: '840' }
 */
function CurrencySelect({
  label,
  width,
  onChange,
  value,
  testId,
  name,
  withFlags,
  primaryCodes,
  programCurrenciesOptions,
  ...restOfProps
}) {
  const [selectedCode, setSelectedCode] = useState(convertToCode(value));
  const options = useOptions(withFlags, primaryCodes, programCurrenciesOptions);
  const valueOption = useMemo(
    () => options.find((option) => option.val === selectedCode),
    [options, selectedCode]
  );

  useEffect(() => {
    setSelectedCode(convertToCode(value));
  }, [value]);

  const handleChange = ({ val }) => {
    setSelectedCode(val);
    const currenciesList = programCurrenciesOptions.length
      ? ISOCurrencyList.filter((currencyObject) =>
          programCurrenciesOptions.includes(currencyObject.code)
        )
      : ISOCurrencyList;

    const currencyObject = currenciesList.find(({ code }) => code === val);
    onChange(currencyObject);
  };

  return (
    <Select
      testId={testId}
      label={label}
      width={width}
      onChange={handleChange}
      value={valueOption}
      options={options}
      showSearch={true}
      name={name}
      {...restOfProps}
    />
  );
}

CurrencySelect.propTypes = {
  label: PropTypes.string,
  width: PropTypes.number,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hasChangedType: PropTypes.string,
  withFlags: PropTypes.bool,
  primaryCodes: PropTypes.arrayOf(PropTypes.string),
  programCurrenciesOptions: PropTypes.arrayOf(PropTypes.string),
};

CurrencySelect.defaultProps = {
  width: 100,
  initialValue: '',
  testId: 'currency-select',
  name: '',
  disabled: false,
  kind: 'select',
  withFlags: false,
  primaryCodes: [],
  programCurrenciesOptions: [],
};

export default CurrencySelect;
