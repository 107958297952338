import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOverlay } from '@mqd/volt-base';
import { ProgramReserveContext } from '../context/ProgramReserveContext.js';

export const programReserveCurrenciesQuery = gql`
  query programReserveCurrencies {
    programReserveCurrencies {
      program
      currency_code
    }
  }
`;

export function withFetchCurrencies(Component) {
  return function WithFetchCurrenciesComponent(props) {
    const { setCurrenciesMap } = useContext(ProgramReserveContext);
    const { loading } = useQuery(programReserveCurrenciesQuery, {
      onCompleted({ programReserveCurrencies }) {
        setCurrenciesMap(programReserveCurrencies);
      },
    });

    return (
      <LoadingOverlay active={loading}>
        <Component {...props} />
      </LoadingOverlay>
    );
  };
}
