import { isNil } from 'ramda';
import constants from '@mqd/mqd-constants';

export const {
  ACCESS_MGMT_MQ_DASHBOARD,
  ACCESS_MGMT_MQ_DASHBOARD_VIEW,
  ACCESS_MGMT_MQ_DASHBOARD_VIEW_AND_EDIT,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL_VIEW,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL_VIEW_AND_EDIT,
  ACCOUNT_HOLDER_DETAILS,
  ACCOUNT_HOLDER_DETAILS_VIEW,
  ACCOUNT_HOLDER_DETAILS_VIEW_AND_EDIT,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
  ACH_PROGRAM_FUNDING,
  ACH_PROGRAM_FUNDING_VIEW,
  ACH_PROGRAM_FUNDING_VIEW_AND_EDIT,
  AUDIT_LOGS,
  AUDIT_LOGS_VIEW,
  BIN_DATA_MANAGEMENT,
  BIN_DATA_MANAGEMENT_VIEW,
  BIN_DATA_MANAGEMENT_VIEW_AND_EDIT,
  BUSINESS_DETAILS,
  BUSINESS_DETAILS_VIEW,
  BUSINESS_DETAILS_VIEW_AND_EDIT,
  CANCEL_ACHO_PROGRAM_FUNDING,
  CANCEL_ACHO_PROGRAM_FUNDING_VIEW_AND_EDIT,
  CARD_DETAILS,
  CREATE_CARD_MANUALLY_VIEW_AND_EDIT,
  CREATE_CARD_MANUALLY,
  CARD_DETAILS_VIEW,
  CARD_DETAILS_VIEW_AND_EDIT,
  CARD_PRODUCTS,
  CARD_PRODUCTS_VIEW,
  CARD_MANAGEMENT,
  CONTROL_CENTER,
  CREDIT,
  CREDIT_ACCOUNT_HOLDER_DETAILS,
  CREDIT_ACCOUNT_HOLDER_DETAILS_VIEW,
  CREDIT_ACCOUNT_PAYMENT_SCHEDULE,
  CREDIT_ACCOUNT_PAYMENT_SCHEDULE_VIEW,
  CREDIT_ACCOUNT_PAYMENT_SOURCE,
  CREDIT_ACCOUNT_PAYMENT_SOURCE_VIEW,
  CUSTOMER_SUPPORT_SWISSQUOTE,
  CUSTOMER_SUPPORT_YUH,
  CUSTOMERS,
  DIGITAL_WALLET_TOKEN_DETAILS,
  DIGITAL_WALLET_TOKEN_DETAILS_VIEW,
  DIGITAL_WALLET_TOKEN_DETAILS_VIEW_AND_EDIT,
  DIGITAL_WALLET,
  DISPUTES_MANUAL_TRANSITIONS,
  DISPUTES_MANUAL_TRANSITIONS_VIEW_AND_EDIT,
  DISPUTES_VIEW_AND_EDIT,
  DISPUTES,
  FULL_ACCOUNT_NUMBER,
  FULL_ACCOUNT_NUMBER_VIEW,
  FULL_PAN,
  FULL_PAN_VIEW,
  INVENTORY_MGMT,
  INVENTORY_MGMT_VIEW,
  INVOKE_COMMANDO_MODE,
  INVOKE_COMMANDO_MODE_VIEW,
  INVOKE_COMMANDO_MODE_VIEW_AND_EDIT,
  KYC_EXCEPTIONS,
  KYC_EXCEPTIONS_VIEW_AND_EDIT,
  MANAGE_ACHO_TRANSFERS,
  MANAGE_ACHO_TRANSFERS_VIEW_AND_EDIT,
  MANAGE_TRANSACTION_ALERTS,
  MANAGE_TRANSACTION_ALERTS_VIEW_AND_EDIT,
  NO_ACCESS,
  permissionsRoleTokenLabelAndRedseaHash,
  PRIVATE_SANDBOX,
  PROCESS_DISPUTES,
  PROCESS_DISPUTES_VIEW_AND_EDIT,
  PROGRAM_CONFIGURATION,
  PROGRAM_CONFIGURATION_VIEW_AND_EDIT,
  PROGRAM_CONTROL,
  REAL_TIME_DECISIONING,
  REAL_TIME_DECISIONING_VIEW_AND_EDIT,
  redseaRoleTokenToPermissionsHash,
  REMOVE_CARDHOLDER_FUNDS,
  REMOVE_CARDHOLDER_FUNDS_VIEW_AND_EDIT,
  REPLACE_CARD,
  REPLACE_CARD_VIEW_AND_EDIT,
  REPORT_STOLEN,
  REPORT_STOLEN_VIEW_AND_EDIT,
  REPORT,
  RETURN_DIRECT_DEPOSIT,
  RETURN_DIRECT_DEPOSIT_VIEW_AND_EDIT,
  RISK_CONTROL,
  SET_PIN,
  SET_PIN_VIEW_AND_EDIT,
  SUSPEND_CARD,
  SUSPEND_CARD_VIEW_AND_EDIT,
  THREE_DS_SETTINGS,
  THREE_DS_SETTINGS_VIEW,
  THREE_DS_SETTINGS_VIEW_AND_EDIT,
  TRANSACTION_REVIEW_REPORT,
  TRANSACTION_REVIEW_REPORT_VIEW,
  UAM_GRANULAR_PERMISSIONS,
  USER_DETAILS_SENSITIVE_VIEW_AND_EDIT,
  USER_DETAILS_SENSITIVE_VIEW,
  USER_DETAILS_SENSITIVE,
  USER_DETAILS_VIEW_AND_EDIT,
  USER_DETAILS_VIEW,
  USER_DETAILS,
  VIEW_AND_EDIT,
  VIEW_ONLY,
} = constants.UAM_GRANULAR_PERMISSIONS;

export const accessTypeNames = [VIEW_AND_EDIT, VIEW_ONLY, NO_ACCESS];

export const SMS = 'SMS';
export const TOTP = 'TOTP';
export const mfaDisplayMapping = {
  [SMS]: 'Authentication via SMS',
  [TOTP]: 'Authentication via Google authenticator',
};

export const MARQETA = 'Marqeta';
export const COMMERCIAL_CREDIT = 'Commercial Credit';
export const CROSS_BORDER_FEES = 'Cross Border Fees';
export const CURRENCY_CONVERSION_FEES = 'Currency Conversion Fees';
export const DETAIL = 'Detail';
export const INTERCHANGE = 'Interchange';
export const LOADS_SETTLEMENT_DATE = 'Loads Settlement Date';
export const STORIES = 'Stories';
export const PII = 'PII';
export const PIN_INTERCHANGE = 'PIN Interchange';
export const THREE_DS = 'ThreeDS';
export const NO_REPORTS = 'No Reports';

export const ANALYTICS = 'Analytics';
export const COMPLIANCE = 'Compliance';

export const janusSupplements = [
  COMMERCIAL_CREDIT,
  CROSS_BORDER_FEES,
  CURRENCY_CONVERSION_FEES,
  DETAIL,
  INTERCHANGE,
  LOADS_SETTLEMENT_DATE,
  STORIES,
  PII,
  PIN_INTERCHANGE,
  THREE_DS,
  NO_REPORTS,
];

export const granularPermissionsCategories = [
  CARD_MANAGEMENT,
  CONTROL_CENTER,
  CREDIT,
  CUSTOMERS,
  DIGITAL_WALLET,
  PROGRAM_CONTROL,
  REPORT,
  RISK_CONTROL,
  // If for some reason we need an "Other" permission section, uncomment line below
  // undefined,
];

export const janusDepartments = [ANALYTICS, COMPLIANCE];

export const swissquoteOnlyRoles = [CUSTOMER_SUPPORT_SWISSQUOTE, CUSTOMER_SUPPORT_YUH, NO_REPORTS];

// **IMPORTANT!!!**
// When adding a new permission please add it to BOTH groupedPermissionsOrder and
// permissionsOrder. If you don't, the permission will not show in the list!

//The permissions list will be sorted according to this array
export const permissionsOrder = [
  ACCESS_MGMT_MQ_DASHBOARD,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL,
  CARD_PRODUCTS,
  USER_DETAILS,
  USER_DETAILS_SENSITIVE,
  ACCOUNT_HOLDER_DETAILS,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE,
  FULL_ACCOUNT_NUMBER,
  MANAGE_ACHO_TRANSFERS,
  CANCEL_ACHO_PROGRAM_FUNDING,
  BUSINESS_DETAILS,
  CREATE_CARD_MANUALLY,
  CARD_DETAILS,
  REPORT_STOLEN,
  REPLACE_CARD,
  SUSPEND_CARD,
  FULL_PAN,
  SET_PIN,
  DIGITAL_WALLET_TOKEN_DETAILS,
  REAL_TIME_DECISIONING,
  KYC_EXCEPTIONS,
  MANAGE_TRANSACTION_ALERTS,
  DISPUTES,
  PROCESS_DISPUTES,
  ANALYTICS,
  COMPLIANCE,
  DETAIL,
  CROSS_BORDER_FEES,
  CURRENCY_CONVERSION_FEES,
  PROGRAM_CONFIGURATION,
  INTERCHANGE,
  STORIES,
  PII,
  THREE_DS,
  COMMERCIAL_CREDIT,
  LOADS_SETTLEMENT_DATE,
  PIN_INTERCHANGE,
  NO_REPORTS,
  ACH_PROGRAM_FUNDING,
  INVOKE_COMMANDO_MODE,
  THREE_DS_SETTINGS,
  AUDIT_LOGS,
  UAM_GRANULAR_PERMISSIONS,
  PRIVATE_SANDBOX,
  BIN_DATA_MANAGEMENT,
  TRANSACTION_REVIEW_REPORT,
  INVENTORY_MGMT,
  RETURN_DIRECT_DEPOSIT,
  CUSTOMER_SUPPORT_SWISSQUOTE,
  CUSTOMER_SUPPORT_YUH,

  CREDIT_ACCOUNT_HOLDER_DETAILS,
  CREDIT_ACCOUNT_PAYMENT_SCHEDULE,
  CREDIT_ACCOUNT_PAYMENT_SOURCE,
];

// Since grouped permissions is still under a feature flag, we need a separate permission order array
export const groupedPermissionsOrder = [
  //Card management
  CARD_DETAILS,
  CARD_PRODUCTS,
  CREATE_CARD_MANUALLY,
  FULL_PAN,
  REPORT_STOLEN,
  REPLACE_CARD,
  SET_PIN,
  SUSPEND_CARD,
  //Control center
  ACCESS_MGMT_MQ_DASHBOARD,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL,
  AUDIT_LOGS,
  INVOKE_COMMANDO_MODE,
  PRIVATE_SANDBOX,
  THREE_DS,
  THREE_DS_SETTINGS,
  //Credit
  CREDIT_ACCOUNT_HOLDER_DETAILS,
  CREDIT_ACCOUNT_PAYMENT_SCHEDULE,
  CREDIT_ACCOUNT_PAYMENT_SOURCE,
  //Customers
  ACCOUNT_HOLDER_DETAILS,
  ACCOUNT_HOLDER_DETAILS_SENSITIVE,
  ACH_PROGRAM_FUNDING,
  BUSINESS_DETAILS,
  CANCEL_ACHO_PROGRAM_FUNDING,
  CUSTOMER_SUPPORT_SWISSQUOTE,
  CUSTOMER_SUPPORT_YUH,
  FULL_ACCOUNT_NUMBER,
  MANAGE_ACHO_TRANSFERS,
  REMOVE_CARDHOLDER_FUNDS,
  RETURN_DIRECT_DEPOSIT,
  USER_DETAILS,
  USER_DETAILS_SENSITIVE,
  //Digital wallet
  DIGITAL_WALLET_TOKEN_DETAILS,
  //Program control
  BIN_DATA_MANAGEMENT,
  UAM_GRANULAR_PERMISSIONS,
  INVENTORY_MGMT,
  PROGRAM_CONFIGURATION,
  //Report
  ANALYTICS,
  COMMERCIAL_CREDIT,
  COMPLIANCE,
  CROSS_BORDER_FEES,
  CURRENCY_CONVERSION_FEES,
  DETAIL,
  INTERCHANGE,
  LOADS_SETTLEMENT_DATE,
  NO_REPORTS,
  PII,
  PIN_INTERCHANGE,
  STORIES,
  //Risk control
  DISPUTES,
  DISPUTES_MANUAL_TRANSITIONS,
  KYC_EXCEPTIONS,
  MANAGE_TRANSACTION_ALERTS,
  PROCESS_DISPUTES,
  REAL_TIME_DECISIONING,
  TRANSACTION_REVIEW_REPORT,
];

export const janusDptsRoleTokenAndLabelHash = {
  [ANALYTICS]: {
    label: ANALYTICS,
    section: REPORT,
    view_only: ANALYTICS,
  },
  [COMPLIANCE]: {
    label: COMPLIANCE,
    section: REPORT,
    view_only: COMPLIANCE,
  },
};
//The supplements below are janus supplements. We will send the actual supplement name as the role token since that is how the janus api accepts them (no concept of role_token/view/edit for these permissions)
export const janusSptsRoleTokenAndLabelHash = {
  [CROSS_BORDER_FEES]: {
    label: CROSS_BORDER_FEES,
    section: REPORT,
    view_only: CROSS_BORDER_FEES,
  },
  [COMMERCIAL_CREDIT]: {
    label: COMMERCIAL_CREDIT,
    section: REPORT,
    view_only: COMMERCIAL_CREDIT,
  },
  [CURRENCY_CONVERSION_FEES]: {
    label: CURRENCY_CONVERSION_FEES,
    section: REPORT,
    view_only: CURRENCY_CONVERSION_FEES,
  },
  [DETAIL]: {
    label: DETAIL,
    section: REPORT,
    view_only: DETAIL,
  },
  [INTERCHANGE]: {
    label: INTERCHANGE,
    section: REPORT,
    view_only: INTERCHANGE,
  },
  [LOADS_SETTLEMENT_DATE]: {
    label: LOADS_SETTLEMENT_DATE,
    section: REPORT,
    view_only: LOADS_SETTLEMENT_DATE,
  },
  [PIN_INTERCHANGE]: {
    label: PIN_INTERCHANGE,
    section: REPORT,
    view_only: PIN_INTERCHANGE,
  },
  [STORIES]: {
    label: STORIES,
    section: REPORT,
    view_only: STORIES,
  },
  [PII]: {
    label: PII,
    section: REPORT,
    view_only: PII,
  },
  [THREE_DS]: {
    label: 'ThreeDS reporting',
    section: CONTROL_CENTER,
    view_only: THREE_DS,
  },
  [NO_REPORTS]: {
    label: 'No Reports',
    section: REPORT,
    view_only: NO_REPORTS,
  },
};

export const allPermissions = {
  ...permissionsRoleTokenLabelAndRedseaHash,
  ...janusSptsRoleTokenAndLabelHash,
  ...janusDptsRoleTokenAndLabelHash,
};

export const initialPermissions = Object.keys(allPermissions).map((permission) => {
  return {
    no_access: { value: true, disabled: false },
    role_token: permission,
    section: allPermissions?.[permission]?.section,
    view_only: {
      value: false,
      disabled: isNil(allPermissions?.[permission]?.[VIEW_ONLY]),
    },
    view_and_edit: {
      value: false,
      disabled: isNil(allPermissions?.[permission]?.[VIEW_AND_EDIT]),
    },
  };
});

export const uamRoleTokensRaw = Object.keys(redseaRoleTokenToPermissionsHash);
export const uamRoleTokensBasePermission = Object.keys(permissionsRoleTokenLabelAndRedseaHash);
export const UAM_ADD_USER_PERMISSIONS = [
  ACCESS_MGMT_MQ_DASHBOARD_VIEW_AND_EDIT,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL_VIEW_AND_EDIT,
];
export const UAM_ACCESS_MGMT_PERMISSIONS = [
  ACCESS_MGMT_MQ_DASHBOARD_VIEW_AND_EDIT,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL_VIEW_AND_EDIT,
  ACCESS_MGMT_MQ_DASHBOARD_VIEW,
  ACCESS_MGMT_MQ_DASHBOARD_EXTERNAL_VIEW,
];

export const delegationOnlyPermissions = [
  ANALYTICS,
  COMMERCIAL_CREDIT,
  COMPLIANCE,
  CROSS_BORDER_FEES,
  CURRENCY_CONVERSION_FEES,
  DETAIL,
  FULL_PAN,
  INTERCHANGE,
  LOADS_SETTLEMENT_DATE,
  PII,
  PIN_INTERCHANGE,
  PROCESS_DISPUTES,
  REMOVE_CARDHOLDER_FUNDS,
  SET_PIN,
  STORIES,
  USER_DETAILS_SENSITIVE,
];

// This object configures nested permissions to display in UI
export const NESTED_PERMISSIONS = {
  [MANAGE_ACHO_TRANSFERS]: [USER_DETAILS, BUSINESS_DETAILS, ACCOUNT_HOLDER_DETAILS],
  [CANCEL_ACHO_PROGRAM_FUNDING]: [ACCOUNT_HOLDER_DETAILS],
  [CARD_DETAILS]: [USER_DETAILS],
  [CREATE_CARD_MANUALLY]: [CARD_DETAILS],
  [FULL_ACCOUNT_NUMBER]: [ACCOUNT_HOLDER_DETAILS],
  [FULL_PAN]: [CARD_DETAILS],
  [REPLACE_CARD]: [CARD_DETAILS],
  [REPORT_STOLEN]: [CARD_DETAILS],
  [SET_PIN]: [CARD_DETAILS],
  [SUSPEND_CARD]: [CARD_DETAILS],
};
