const disputesConfigRoutes = [
  {
    path: '/dispute-onboard-program',
    name: 'Onboard Program',
    importer: () => import(/*webpackChunkName: "OnBoard Program" */ '../OnBoardProgramPage.js'),
  },
  {
    path: '/program-reg-config',
    name: 'Program Reg Config',
    importer: () =>
      import(/*webpackChunkName: "Program Reg Config" */ '../ProgramRegConfigPage.js'),
  },
];

export default disputesConfigRoutes;
