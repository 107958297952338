import { fdsRoutes } from '../../views/fds/routing/config.js';
import { webhooksRoutes } from '../../views/development/webhooks-routes.js';
import approvalQueueRoutes from '../../views/approval-queue/config.js';
import adminUserDetailsRoutes from '../../views/admin/uam-granular-permissions/config.js';
import acceptedCountriesRoutes from '../../views/accepted-countries/config.js';
import applicationTokensRoutes from '../../views/application-tokens/config.js';
import cardProductRoutes from '../../views/card-products/config.js';
import dataCatalogRoutes from '../../views/data-catalog/config.js';
import mccGroupsRoutes from '../../views/mcc-groups/config.js';
import programControlsRoutes from '../../views/program-controls/config.js';
import transactionDetailsRoutes from '../../views/program/TransactionDetails/config.js';
import { inventoryRoutes } from '../../views/program/Inventory/routes/config';
import disputesConfigRoutes from '../../views/disputes/disputes-config/routes/config.js';
// Via the 2021 refactor of routing methods: https://github.marqeta.com/mqd/wam/blob/develop/apps/amc/root/src/stores/router/README.md
// New routes should be registered in this function.
const registerRoutes = (router) => {
  // Register FDS routes.
  router.register(fdsRoutes);
  router.register(webhooksRoutes);

  // Program Controls and ApprovalQueue
  router.register(approvalQueueRoutes);
  router.register(acceptedCountriesRoutes);
  router.register(applicationTokensRoutes);
  router.register(cardProductRoutes);
  router.register(mccGroupsRoutes);
  router.register(programControlsRoutes);

  //UAM
  router.register(adminUserDetailsRoutes);

  // Inventory detailed view
  router.register(inventoryRoutes);

  // Program - User - Transaction details
  router.register(transactionDetailsRoutes);

  // Data Marketplace view
  router.register(dataCatalogRoutes);

  //Disputes Config
  router.register(disputesConfigRoutes);
};

export default registerRoutes;
