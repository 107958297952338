import React, { useMemo } from 'react';
import { ISOCurrencyList } from './constants.js';
import { getOptionsSorter } from './helpers.js';
import CurrencyFlagOption from './components/CurrencyFlagOption/CurrencyFlagOption.js';

export const useOptions = (withFlags, primaryCodes, programCurrenciesOptions) => {
  return useMemo(() => {
    let currenciesList = ISOCurrencyList;
    if (programCurrenciesOptions?.length) {
      currenciesList = currenciesList.filter((currency) =>
        programCurrenciesOptions.includes(currency.code)
      );
    }

    let codes = currenciesList.map((currencyObject) => currencyObject.code);

    if (primaryCodes.length) {
      codes.sort(getOptionsSorter(primaryCodes));
    }

    return codes.map((code) => ({
      id: code,
      val: code,
      render: withFlags ? <CurrencyFlagOption code={code} /> : code,
    }));
  }, [withFlags, primaryCodes, programCurrenciesOptions]);
};
